hr {
	margin: 30px 20px;
}

.list-unstyled {
	padding-left: 0;
	list-style: none;
}

.list-inline li {
	display: inline-block;
	padding-right: 5px;
	padding-left: 5px;
	margin-bottom: 10px;
}

/*---- Genral classes end -------*/

/*Change icons size here*/
.social-icons .fab {
	font-size: 1em;
}

/*Change icons circle size and color here*/
.social-icons .fab {
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	color: #000;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.social-icons.icon-circle .fab {
	border-radius: 50%;
}

.social-icons.icon-rounded .fab {
	border-radius: 5px;
}

.social-icons.icon-flat .fab {
	border-radius: 0;
}

.social-icons .fab:hover,
.social-icons .fab:active {
	color: #404040;
}

.social-icons.icon-zoom .fab:hover,
.social-icons.icon-zoom .fab:active {
	-webkit-transform: scale(1.1);
	-moz-transform: scale(1.1);
	-ms-transform: scale(1.1);
	-o-transform: scale(1.1);
	transform: scale(1.1);
}

.social-icons.icon-rotate .fab:hover,
.social-icons.icon-rotate .fab:active {
	-webkit-transform: scale(1.1) rotate(360deg);
	-moz-transform: scale(1.1) rotate(360deg);
	-ms-transform: scale(1.1) rotate(360deg);
	-o-transform: scale(1.1) rotate(360deg);
	transform: scale(1.1) rotate(360deg);
}

.social-icons .fab {
	background-color: #fff;
}